// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-accountable-ads-js": () => import("./../../../src/pages/works/accountable-ads.js" /* webpackChunkName: "component---src-pages-works-accountable-ads-js" */),
  "component---src-pages-works-atlantic-joint-center-js": () => import("./../../../src/pages/works/atlantic-joint-center.js" /* webpackChunkName: "component---src-pages-works-atlantic-joint-center-js" */),
  "component---src-pages-works-atlantic-spine-center-js": () => import("./../../../src/pages/works/atlantic-spine-center.js" /* webpackChunkName: "component---src-pages-works-atlantic-spine-center-js" */),
  "component---src-pages-works-book-appointment-js": () => import("./../../../src/pages/works/book-appointment.js" /* webpackChunkName: "component---src-pages-works-book-appointment-js" */),
  "component---src-pages-works-collective-care-js": () => import("./../../../src/pages/works/collective-care.js" /* webpackChunkName: "component---src-pages-works-collective-care-js" */),
  "component---src-pages-works-doctor-consulting-js": () => import("./../../../src/pages/works/doctor-consulting.js" /* webpackChunkName: "component---src-pages-works-doctor-consulting-js" */),
  "component---src-pages-works-mountain-spine-js": () => import("./../../../src/pages/works/mountain-spine.js" /* webpackChunkName: "component---src-pages-works-mountain-spine-js" */),
  "component---src-pages-works-pain-diagnosed-js": () => import("./../../../src/pages/works/pain-diagnosed.js" /* webpackChunkName: "component---src-pages-works-pain-diagnosed-js" */),
  "component---src-pages-works-react-three-fiber-js": () => import("./../../../src/pages/works/react-three-fiber.js" /* webpackChunkName: "component---src-pages-works-react-three-fiber-js" */),
  "component---src-pages-works-resume-js": () => import("./../../../src/pages/works/resume.js" /* webpackChunkName: "component---src-pages-works-resume-js" */),
  "component---src-pages-works-rig-planet-js": () => import("./../../../src/pages/works/rig-planet.js" /* webpackChunkName: "component---src-pages-works-rig-planet-js" */),
  "component---src-pages-works-swimming-pool-js": () => import("./../../../src/pages/works/swimming-pool.js" /* webpackChunkName: "component---src-pages-works-swimming-pool-js" */)
}

