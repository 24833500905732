export const menuItems = [
    {
        name: 'works',
        label: 'recent works',
        items: [
            {
                name: 'works/resume/',
                label: 'personal resume'
            },
            {
                name: 'works/book-appointment/',
                label: 'book appointment'
            },
            {
                name: 'works/pain-diagnosed/',
                label: 'pain diagnosed'
            },
            {
                name: 'works/accountable-ads/',
                label: 'accountable ads'
            },
            {
                name: 'works/collective-care/',
                label: 'collective care'
            },
            {
                name: 'works/doctor-consulting/',
                label: 'doctor consulting'
            },
            {
                name: 'works/rig-planet/',
                label: 'rigplanet'
            },
            // {
            //     name: 'works/mountain-spine/',
            //     label: 'mountain spine'
            // },
            {
                name: 'works/atlantic-spine-center/',
                label: 'atlantic spine center'
            },
            {
                name: 'works/atlantic-joint-center/',
                label: 'atlantic joint center'
            }
            // {
            //     name: 'works/react-three-fiber/',
            //     label: 'react three fiber'
            // },
            // { name: 'works/swimming-pool/', label: 'pool rebuild' }
        ]
    }
]
