import { useCallback } from 'react'
import qs from 'qs'
//import { useTracking } from './useTracking'

// changed evaluation to body
const useFormPost = endpoint => {
    //const tracking = useTracking()

    const create = useCallback(
        (values, extraField = false, addBodyField = []) => {
            return new Promise((resolve, reject) => {
                const source = typeof location !== 'undefined' && location.host
                const code = new Date().getTime().toString(36)

                let bodyValues = {}
                let extraValue = {}

                if (extraField) {
                    const {
                        name,
                        email,
                        mobile,
                        subject,
                        message,
                        subtype,
                        ...xtraValues
                    } = values

                    const keys = Object.keys(xtraValues)
                    const strExtra = keys.reduce(
                        (a, k) =>
                            `${a}${a === '' ? '' : '\n'}${k}:${xtraValues[k]}`,
                        ''
                    )

                    bodyValues = {
                        name,
                        email,
                        mobile,
                        subject,
                        subtype,
                        message,
                        [extraField]: strExtra
                    }
                } else {
                    bodyValues = values
                }

                const additionalBodyValues =
                    addBodyField &&
                    addBodyField.reduce((acc, key) => {
                        return { ...acc, [key]: values[key] }
                    }, {})

                const body = {
                    ...bodyValues,
                    ...additionalBodyValues,
                    //...tracking,
                    code,
                    source
                }

                const strValues = qs.stringify(body)
                //const url = `${endpoint}?${strValues}`
                const url = endpoint

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json' // Specify the content type as JSON
                        // You may need to include additional headers depending on your API requirements
                    },
                    body: JSON.stringify(body)
                })
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        [endpoint] //, tracking]
    )

    return create
}

export { useFormPost }
