import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Span } from '../../components/Core'
//import imgPhoto from '../../assets/image/jpg/portfolio-about.jpg'
import imgPhoto from '../../assets/me.jpg'
import imgSignLight from '../../assets/image/png/signature-light.png'

const LinkSocial = styled.a`
    color: ${({ theme }) => theme.colors.light} !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1.63px;
    transition: 0.4s;
    &:hover {
        color: ${({ theme }) => theme.colors.light} !important;
        transform: translateY(-5px);
    }
`

const About = ({ hero = true, bg = 'dark', ...rest }) => {
    return (
        <>
            <Section hero={hero} bg={bg} {...rest}>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6">
                            <div>
                                <img
                                    src={imgPhoto}
                                    alt="folio"
                                    className="img-fluid"
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="pl-lg-4 pt-5 pt-lg-0">
                                <Title color="light" variant="secSm">
                                    Casey Flynn
                                </Title>
                                <Text
                                    color="light"
                                    className="mt-3 mt-lg-5"
                                    css={`
                                        line-height: 1.5;
                                    `}
                                >
                                    Full Stack developer with over 25 years
                                    experience. I build it all.
                                </Text>
                                <div className="mt-4">
                                    <Text color="light">Email me at</Text>

                                    <Text variant="p">
                                        <a
                                            href="mailto:caseys.cv@gmail.com"
                                            className="font-weight-bold"
                                        >
                                            <Span color="light">
                                                caseys.cv@gmail.com
                                            </Span>
                                        </a>
                                    </Text>
                                </div>
                                <div className="mt-5">
                                    <img
                                        src={imgSignLight}
                                        alt="sign"
                                        className="img-fluid"
                                    />
                                </div>
                                <Text
                                    color="light"
                                    className="mt-3 font-weight-bold"
                                >
                                    Casey Flynn
                                </Text>
                                <Text color="light" variant="small">
                                    Full Stack Developer
                                </Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

// <div className="mt-5 d-flex">
//     <LinkSocial
//         href="https://www.dribbble.com"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="mr-3"
//     >
//         Dribble
//     </LinkSocial>
//     <LinkSocial
//         href="https://www.twitter.com"
//         target="_blank"
//         rel="noopener noreferrer"
//         className=" mr-3"
//     >
//         Twitter
//     </LinkSocial>
//     <LinkSocial
//         href="https://www.facebook.com"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="mr-3"
//     >
//         Facebook
//     </LinkSocial>
//     <LinkSocial
//         href="https://www.instagram.com"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="font-weight-bold"
//     >
//         Instagram
//     </LinkSocial>
// </div>

export default About
