import React, { useState } from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { useFormPost } from '../../hooks/useFormPost'

import {
    Section,
    Title,
    Text,
    Span,
    Box,
    LinkContact
} from '../../components/Core'
import ContactForm from '../../components/ContactForm'
import { device } from '../../utils'
import { useForm } from 'react-hook-form'

const ContactCard = styled.div`
    border-radius: 10px;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    margin-top: 3rem;
    @media ${device.lg} {
        margin-top: 250px;
    }
`

const Thankyou = styled.div`
    border-radius: 10px;
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
    margin-top: 3rem;
    padding: 1em;
`

const Contact = ({ hero = true, bg = 'dark', ...rest }) => {
    const [completed, setCompleted] = useState(false)

    const postForm = useFormPost(
        'https://us-east4-caseycv.cloudfunctions.net/contact-forms'
    )

    const { handleSubmit, control, register, formState, clearErrors } = useForm(
        {
            defaultValues: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        }
    )

    const handleOnSubmit = data => {
        // const form = evt.currentTarget
        //
        // evt.preventDefault()
        // evt.stopPropagation()

        console.log(data)
        //console.log(form)

        postForm(data, 'extra')
            .then(resp => {
                //gapush('form-submit', { contactType })
                setCompleted(true)
            })
            .catch(error => {
                //gapush('form-error', { contactType })
                alert('Error Submitting Your Information')
            })
    }

    return (
        <>
            <Section hero={hero} bg={bg} {...rest}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="6">
                            <Box className="pr-lg-5">
                                <Title color="light" variant="secSm" mb="2rem">
                                    Contact now
                                </Title>
                                <Text color="light" mb="2.75rem">
                                    Have a project or question? Send me a
                                    message. I will reply within 48 hours.
                                </Text>

                                {!completed && (
                                    <ContactForm
                                        theme="dark"
                                        onSubmit={handleSubmit(handleOnSubmit)}
                                        control={control}
                                        register={register}
                                    />
                                )}

                                {completed && (
                                    <Thankyou>
                                        <Text color="light" mb="2rem">
                                            Submission Complete
                                        </Text>
                                        <Text color="light" mb="2.75rem">
                                            Thank you for your interest. I'll
                                            contact you shortly.
                                        </Text>
                                    </Thankyou>
                                )}
                            </Box>
                        </Col>
                        <Col lg="5">
                            <ContactCard className="p-5 ml-lg-5">
                                <Box mb="2rem">
                                    <Text color="light">Email me at</Text>

                                    <a
                                        href="mailto:caseys.cv@gmail.com"
                                        className="font-weight-bold"
                                    >
                                        <Span color="primary">
                                            caseys.cv@gmail.com
                                        </Span>
                                    </a>
                                </Box>

                                <Box>
                                    <Text color="light">Get Connected</Text>

                                    <LinkContact
                                        href="https://www.linkedin.com/in/casey-flynn-0238a2205/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        linkedin.com/casey
                                    </LinkContact>
                                </Box>
                            </ContactCard>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default Contact
