import React from 'react'

import { Form } from 'react-bootstrap'

import { Input, TextArea, Button } from '../Core'

const ContactForm = ({ theme = 'dark', control, register, ...rest }) => {
    return (
        <Form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            {...rest}
        >
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="contact" />
            <div className="mt-4">
                <Input
                    type="text"
                    placeholder="Your name"
                    required
                    name="name"
                    control={control}
                />
            </div>
            <div className="mt-4">
                <Input
                    type="email"
                    placeholder="Email address"
                    required
                    name="email"
                    control={control}
                />
            </div>
            <div className="mt-4">
                <Input
                    type="text"
                    placeholder="Subject"
                    required
                    name="subject"
                    control={control}
                />
            </div>
            <div className="mt-4 ">
                <TextArea
                    rows={4}
                    placeholder="Write your message"
                    required
                    name="message"
                    control={control}
                    register={register}
                />
            </div>
            <div className="mt-4 mt-lg-5">
                <Button arrowRight variant="primary" type="submit">
                    SEND
                </Button>
            </div>
        </Form>
    )
}

export default ContactForm
